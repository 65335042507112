
import { useState, useEffect, useContext } from 'react';
import { Logo } from '../Assets/Main'; // Assuming Logo is the default export
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { CheckAuthContext } from '../context/checkAuth';
import Authentication from './Authentication'
export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [authScreen,setAuthScreen] = useState(false);
  const Autheticated = useContext(CheckAuthContext);
  const [navbarStyle, setNavbarStyle] = useState({
    backgroundColor: 'transparent',
    textColor: '#000',
    boxShadow: 'none',
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarStyle({
          backgroundColor: '#fff',
          textColor: '#000',
          boxShadow: '0 0 10px rgba(0,0,0,0.2)',
        });
      } else {
        setNavbarStyle({
          backgroundColor: 'transparent',
          textColor: location === '/' ? '#fff' : '#000',
          boxShadow: 'none',
        });
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  useEffect(() => {
    const checkMobileView = () => {
      if (window.innerWidth <= 768) setIsMobile(true);
      else setIsMobile(false); // Reset if not mobile view
    };

    checkMobileView();

    const customer_id = localStorage.getItem('customer_id');
    const token = localStorage.getItem('token');
    Autheticated.setCheckAuth((customer_id && token));

    window.addEventListener('resize', checkMobileView);
    return () => {
      window.removeEventListener('resize', checkMobileView);
    };
  });

  function  setAuthPart(){
    setAuthScreen(false);
  }
  return (
    
    <nav
      className="fixed z-20 w-full transition-all duration-300"
      style={{ backgroundColor: navbarStyle.backgroundColor, boxShadow: navbarStyle.boxShadow, top: 0 }}
    >
       {authScreen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">

          <div className="bg-white rounded-lg shadow-lg w-[90%] max-w-[500px] p-6 relative">
           
            <Authentication sendMessage={setAuthPart}/> 
          </div>
        </div>
      )}
      <div className="container mx-auto flex items-center justify-between py-2 px-4 md:px-[0px]">
        <div className="flex items-center space-x-2 w-[100px]">
          <Link to="/">
            <img src={Logo} alt="Logo" className="h-[100%] w-[100%]" />
          </Link>
        </div>

        {/* Desktop Menu */}
        <div className="hidden md:flex space-x-8 w-[60%]">
          <ul className="flex justify-around flex-wrap space-x-8 w-[100%]">
            <Link to="/">
              <li className="hover:text-blue-500 cursor-pointer text-[20px] font-bold" style={{ color: !isMobile ? navbarStyle.textColor : '#000' }}>
                Home
              </li>
            </Link>
            <Link to="/products">
              <li className="hover:text-[#26848E] cursor-pointer text-[20px] font-bold" style={{ color: !isMobile ? navbarStyle.textColor : '#000' }}>
                Products
              </li>
            </Link>
            {/* <Link to="/child_safety">
              <li className="hover:text-blue-500 cursor-pointer text-[20px] font-bold" style={{ color: !isMobile ? navbarStyle.textColor : '#000' }}>
                Child Safety
              </li>
            </Link> */}
            {/* <Link to="/schools">
              <li className="hover:text-blue-500 cursor-pointer text-[20px] font-bold" style={{ color: !isMobile ? navbarStyle.textColor : '#000' }}>
                Schools
              </li>
            </Link> */}
            <Link to="/contactus">
              <li className="hover:text-blue-500 cursor-pointer text-[20px] font-bold" style={{ color: !isMobile ? navbarStyle.textColor : '#000' }}>
                Contact Us
              </li>
            </Link>
          </ul>
        </div>

        {/* Desktop Login/Profile Buttons */}
        <div className="hidden md:flex space-x-4">
          {Autheticated.checkAuth ? (
            <button
              onClick={() => navigate('/dashboard')}
              className="bg-[#26848e] hover:bg-[#268489] text-white w-[120px] h-[45px] px-4 py-2 rounded transition duration-300 flex justify-center items-center text-[20px] font-bold"
              style={{ backgroundColor: navbarStyle.backgroundColor === 'transparent' ? '#26848e' : '#000', color: '#fff' }}
            >
              Profile
            </button>
          ) : (
            <button
              onClick={() => setAuthScreen(true)}
              className="bg-[#26848e] hover:bg-[#268489] text-white w-[100px] h-[35px] px-4 py-2 rounded transition duration-300 flex justify-center items-center"
              style={{ backgroundColor: navbarStyle.backgroundColor === 'transparent' ? '#26848e' : '#000', color: '#fff' }}
            >
              Login
            </button>
          )}
        </div>

        {/* Mobile Menu Toggle Button */}
        <div className="md:hidden flex justify-between items-center">
          <button
            className="bg-[#26848e] text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            Menu
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-white shadow-lg py-4">
          <ul className="flex flex-col items-center space-y-4">
            <Link to="/">
              <li className="hover:text-blue-500 cursor-pointer text-[15px]" style={{ color: '#000' }} onClick={() => setIsMobileMenuOpen(false)}>
                Home
              </li>
            </Link>
            <Link to="/products">
              <li className="hover:text-[#26848E] cursor-pointer text-[15px]" style={{ color: '#000' }} onClick={() => setIsMobileMenuOpen(false)}>
                Products
              </li>
            </Link>
            {/* <Link to="/child_safety">
              <li className="hover:text-blue-500 cursor-pointer text-[15px]" style={{ color: '#000' }} onClick={() => setIsMobileMenuOpen(false)}>
                Child Safety
              </li>
            </Link>
            <Link to="/schools">
              <li className="hover:text-blue-500 cursor-pointer text-[15px]" style={{ color: '#000' }} onClick={() => setIsMobileMenuOpen(false)}>
                Schools
              </li>
            </Link> */}
            <Link to="/contactus">
              <li className="hover:text-blue-500 cursor-pointer text-[15px]" style={{ color: '#000' }} onClick={() => setIsMobileMenuOpen(false)}>
                Contact Us
              </li>
            </Link>
          </ul>
          <div className="mt-4 flex justify-center">
            {Autheticated.checkAuth ? (
              <button
                onClick={() => {
                  
                  navigate('/dashboard');
                  setIsMobileMenuOpen(false);
                }}
                className="bg-[#26848e] text-white w-[100px] h-[35px] px-4 py-2 rounded transition duration-300"
              >
                Profile
              </button>
            ) : (
              <button
                onClick={() => {
                 
                  navigate('/authentication');
                  setIsMobileMenuOpen(false);
                }}
                className="bg-[#26848e] text-white w-[100px] h-[35px] px-4 py-2 rounded transition duration-300"
              >
                Login
              </button>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}
