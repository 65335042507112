import React from "react";
import {
  Logo,
  bluedart,
  playStore,
  AppStore,
  Gpay,
  Visa,
  upi,
  MasterCard,
  AE,
  Paytm,
  Rupay,
  Shiprocket,
  Delhivery,
} from "../Assets/Main"; // Assuming Logo is the default export
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaYoutubeSquare,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="bg-black text-white p-8 md:p-12 max-sm:p-[10px]">
        <img src={Logo} alt="onelap" className="w-32" />{" "}
        {/* Replace with actual image */}
        <div className="max-w-7xl flex flex-col md:flex-row justify-between items-start space-y-6 md:space-y-0">
          {/* Left Section - Logo and Description */}
          <div className="md:w-1/3 space-y-4">
            <p className="text-[17px] leading-relaxed">
              GANS GPS is India’s most popular GPS brand for assets and personal
              safety. We help people control their assets from anywhere in the
              world, resulting in lesser theft, more control, and staying
              connected to their loved ones.
            </p>
            <p className="text-[17px]">
              &copy; 2023 GANS Telematics all rights reserved
            </p>
            <div className="flex space-x-4 text-[30px]">
              {/* Social media icons */}
              <a
                href="https://www.facebook.com/gansgpstrack/"
                aria-label="Facebook"
              >
                <FaFacebook />
              </a>
              <a href="https://www.instagram.com/gansgps/" aria-label="YouTube">
                <FaInstagram />
              </a>
              <a
                href="https://www.linkedin.com/company/gansgps/"
                aria-label="LinkedIn"
              >
                <FaLinkedin />
              </a>
              <a href="https://x.com/gansgpstrack" aria-label="Instagram">
                <FaXTwitter />
              </a>
              <a
                href="https://www.youtube.com/@GlobalAutoNavigationSystem"
                aria-label="Instagram"
              >
                <FaYoutubeSquare />
              </a>
            </div>
            {/* Store Buttons */}
            <div className="flex space-x-4">
              <a
                href="https://play.google.com/store/apps/details?id=uffizio.startupvts&hl=en"
                className="width-[150px]"
                style={{ width: "150px" }}
              >
                <img
                  src={playStore}
                  alt="Google Play"
                  className="w-full width-md-full rounded-md border"
                />{" "}
                {/* Replace with actual image */}
              </a>
              <a
                href="https://apps.apple.com/in/app/startup-vts/id1327365993"
                className="w-24"
                style={{ width: "165px" }}
              >
                <img src={AppStore} alt="App Store" className="w-full" />{" "}
                {/* Replace with actual image */}
              </a>
            </div>
          </div>

          {/* Middle Section - Links */}
          <div className="md:w-1/4 flex flex-col space-y-2">
            <Link to="/privacy_policy" className="text-[17px] hover:underline">
              Privacy Policy
            </Link>
            <Link
              to="/term_and_conditions"
              className="text-[17px] hover:underline"
            >
              Terms and Conditions
            </Link>
            <Link to="/refund_policy" className="text-[17px] hover:underline">
              Cancellation and Refund
            </Link>
            <Link to="/shipping" className="text-[17px] hover:underline">
              Shipping and Delivery
            </Link>
            <Link to="/contactus" className="text-[17px] hover:underline">
              Contact Us
            </Link>
          </div>

          {/* Right Section - Contact Info */}
          <div className="md:w-1/3 space-y-4">
            <p className="text-[17px]">
              Connect on WhatsApp:{" "}
              <a href="tel:+919266420570" className="underline">
                +91 9266420570
              </a>
            </p>
            <p className="text-[17px]">Working hours: 11:00 am - 6:00 pm</p>
            <p className="text-[17px]">
              Email us at:{" "}
              <a href="mailto:support@gansgps.com" className="underline">
                support@gansgps.com
              </a>
            </p>
            <p className="text-[17px]">
              Address: A-74, A-Block, New Ashok Nagar, East Delhi ( 110096 ),
              New Delhi
            </p>
          </div>
          {/* Payment and Logistic Partners Section */}
        </div>
        <div className="mt-10 border-t border-gray-700 pt-6">
          <div className="mx-auto">
            {/* Payment Partners */}
            <div className="mb-8">
              <h3 className="font-semibold text-lg max-sm:text-center">
                Payment Partners
              </h3>
              <div className="flex space-x-4 mt-2 flex-wrap max-sm:grid max-sm:grid-cols-3 max-sm:gap-[20px] max-sm:items-center">
                <img src={Visa} alt="Visa" className="h-5" />
                <img src={Rupay} alt="Rupay" className="h-5" />
                <img src={Paytm} alt="Paytm" className="h-5" />
                <img src={AE} alt="American Express" className="h-5" />
                <img src={MasterCard} alt="MasterCard" className="h-6" />
                <img src={Gpay} alt="Gpay" className="h-5" />
                <img src={upi} alt="UPI" className="h-8" />
              </div>
            </div>

            {/* Logistic Partners */}
            <div className="mb-8">
              <h3 className="font-semibold text-[17px] max-sm:text-center max-sm:mb-[20px]">
                Logistic Partners
              </h3>
              <div className="flex items-end space-x-1 mt-2 gap-[15px] flex-wrap max-sm:grid max-sm:grid-cols-3 max-sm:gap-[5px] max-sm:items-center">
                <img src={Delhivery} alt="Delhivery" className="h-5" />
                <img
                  src={Shiprocket}
                  alt="Shiprocket Fulfillment"
                  className="h-9"
                />
                <img src={bluedart} alt="bluedart " className="h-5 " />
              </div>
            </div>

            {/* E-waste Disclaimer */}
            <p className="text-[17px]">
              Please dispose of e-waste and plastic waste responsibly. For more
              information or e-waste pickup, please click{" "}
              <a href="#" className="underline">
                here
              </a>{" "}
              for more details.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
