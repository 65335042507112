import React, { useEffect, useState } from 'react';
import {Digital, MainSlider1, MainSlider2, MainSlider3, MainSlider4, childrenSafety, MainSlider5, buschildsafety, slidermain6, slider2, slider3, slider4, slider5} from '../Assets/Main';
import { Link } from 'react-router-dom';

export default function Header() {
  const [slide, setSlides] = useState(1);

  // Auto-slide functionality
  // useEffect(() => {
  //   const changeSlider = setInterval(() => {
  //     setSlides((prevSlide) => (prevSlide % 4) + 1); // loop through slides 1-4
  //   }, 3000); // change slide every 5 seconds

  //   return () => clearInterval(changeSlider); // Cleanup the interval on unmount
  // }, []);

  const styles = {
    Slider_1: {
      backgroundImage: `url('${slider2}')`,
    },
    Slider_4: {
      backgroundImage: `url('${slider5}')`,
    },
    Slider_2: {
      backgroundImage: `url('${slider3}')`,
    },
    Slider_3: {
      backgroundImage: `url('${slider4}')`,
    },
    Slider_5: {
      backgroundImage: `url('${MainSlider5}')`,
    },
  };

  const handleDotClick = (index) => {
    setSlides(index);
  };

  return (
    <>
      {slide === 1 && (
        <header
          className="relative bg-cover bg-center h-[100vh] flex items-center justify-evenly pt-[40px] max-sm:flex-col-reverse	max-sm:justify-center"
          style={styles.Slider_1}
        >
          <div className='text-[#000] text-center w-[25%] max-sm:w-[80%] max-sm:m-0'>
            <h1 className='text-[2.5rem] font-[500] text-[#000] max-sm:text-[1.3rem]'>
              <span>Never Lose Sight </span> of What Matters
            </h1>
            <p className='text-[20px] font-bold mb-[30px] text-[1.1rem] text-[#000] max-sm:text-[.6rem]'>
              Real-Time GPS Tracking for Complete Control and Assurance
            </p>
            {/* <input
              type="text"
              className='w-[100%] h-[45px] pl-[10px] rounded-[5px] mb-[20px]'
              placeholder='Enter your mobile number'
            /> */}
            <br />
            <a href="tel:+919266420570">
  <button className='w-[100%] h-[45px] pl-[10px] bg-[#26848E] text-[#fff] rounded-[10px] text-[20px] shadow-lg font-[700]'>
    Talk to expert
  </button>
</a>

          </div>
          <img
            src={'https://www.uffizio.com/wp-content/themes/uffizio/slider/banner01.svg'}
            alt="traking GPS"
            className='w-[45%] object-cover max-sm:w-[80%]  max-sm:m-0'
          />
        </header>
      )}
      {slide === 2 && (
        <header
          className="relative bg-cover bg-center h-[100vh] flex items-center justify-start pt-[40px] pl-[60px] max-sm:flex-col-reverse	max-sm:justify-center max-sm:bg-[67%] max-sm:justify-left max-sm:pt-[2px] max-sm:pl-0"
          style={styles.Slider_2}
        >
          <div className='text-[#000] text-center w-[25%] max-sm:w-[80%] max-sm:m-0'>
            <h1 className='text-[2.5rem] font-[500] text-[#fff] max-sm:text-[1.3rem]'>
              <span>Never Lose Sight </span> of What Matters
            </h1>
            <p className='text-[20px] mb-[px] text-[1.1rem] text-[#fff] max-sm:text-[.6rem]'>
              Real-Time GPS Tracking for Complete Control and Assurance
            </p>
            {/* <input
              type="text"
              className='w-[100%] h-[45px] pl-[10px] rounded-[5px] mb-[20px]'
              placeholder='Enter your mobile number'
            /> */}
            <br />
            <a href="tel:+919266420570">
  <button className='w-[100%] h-[45px] pl-[10px] bg-[#26848E] text-[#fff] rounded-[10px] text-[20px] shadow-lg font-[700]'>
    Talk to expert
  </button>
</a>

          </div>
        </header>
      )}
      {slide === 3 && (
        <header
          className="relative bg-cover bg-center h-[100vh] flex items-center justify-start pt-[40px] pl-[20px] max-sm:bg-[67%_21%]"
          style={styles.Slider_4}
        >
          <div className='text-[#000] text-start w-[35%] mr-[30px] max-sm:w-[90%] max-sm:m-0'>
            <h1 className='text-[2.5rem] font-[500] text-[#000] max-sm:text-[1.3rem]'>
              Always Know Your Child&apos;s Location with Our GPS Tracker
            </h1>
            <p className='text-[20px] mb-[30px] text-[1.1rem] text-[#000] max-sm:text-[.6rem]'>
              Real-Time Location Updates for Complete Peace of Mind – Keeping Your Loved Ones Safe, Anytime, Anywhere.
            </p>
            <div className='flex justify-between max-sm:justify-around'>
              <Link to="/products" className='w-[100%]'>
              <button className='w-[100%] h-[45px] pl-[10px] bg-[#fff] text-[#000] rounded-[5px] shadow-lg font-[700] tracking-[1px] text-[20px] w-[48%]'>
                Explore
              </button>
              </Link>
            </div>
          </div>
        </header>
      )}
      {slide === 4 && (
        <header
          className="relative bg-cover bg-center h-[100vh] flex items-center max-sm:flex-col-reverse justify-center pt-[40px]"
          style={styles.Slider_5}
        >
          <div className='text-[#000] text-center w-[35%] mr-[30px] max-sm:w-[90%] max-sm:m-0'>
            <h1 className='text-[2.5rem] font-[500] text-[#fff] max-sm:text-[1.3rem]'>
              Track Your Student’s Safety with Our GPS Solution
            </h1>
            <p className='text-[.95rem] mb-[30px] text-[20px] text-[#fff] max-sm:text-[.6rem]'>
              Ensure School Attendance and Safe Travel with Real-Time GPS Monitoring for Students.
            </p>
            <div className='flex justify-between max-sm:justify-center'>
              <Link to="/products" className='w-[100%]'>
                <button className='w-[100%] h-[45px] pl-[10px] bg-[#fff] text-[#000] rounded-[5px] shadow-lg font-[700] tracking-[1px] w-[48%] text-[20px]'>
                  Explore
                </button>
              </Link>
            </div>
            
          </div>
          <img
            src={buschildsafety}
            alt="bus child safety"
            className='w-[36%] object-cover max-sm:w-[80%] max-sm:m-0'
          />
        </header>
      )}

      {/* Dots Navigation */}
      <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 flex space-x-3">
        {[1, 2, 3, 4].map((index) => (
          <div
            key={index}
            onClick={() => handleDotClick(index)}
            className={`w-2 h-2 rounded-full cursor-pointer ${slide === index ? 'bg-[#fff]' : 'bg-gray-400'}`}
          ></div>
        ))}
      </div>
    </>
  );
}
