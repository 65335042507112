import React from "react";

const ShippingDelivery = () => {
  return (
    <div className="bg-gray-100 min-h-screen p-8 relative top-[70px]">
      <div className="max-w-3xl mx-auto bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-3xl text-center font-bold mb-4">
          Shipping and Delivery Policy
        </h1>

        <h2 className="text-xl font-semibold mt-6 mb-2">1. Shipping Methods</h2>
        <p className="mb-4">
          At GANS, we offer multiple shipping options to ensure your order
          reaches you in a timely and efficient manner.
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>Standard Delivery: 5-7 business days</li>
          <li>Express Delivery: 2-3 business days</li>
          <li>Same-Day Delivery (available in select cities)</li>
        </ul>

        <h2 className="text-xl font-semibold mt-6 mb-2">2. Shipping Charges</h2>
        <p className="mb-4">
          Shipping charges may vary based on the shipping method you choose and
          your location. The exact cost will be calculated at checkout.
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">3. Order Processing</h2>
        <p className="mb-4">
          Orders are typically processed within 1-2 business days. You&apos;ll
          receive a confirmation email with your tracking number once your order
          has been shipped.
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">
          4. Razorpay Payment Integration
        </h2>
        <p className="mb-4">
          We accept payments through Razorpay, one of the most trusted payment
          gateways. You can pay using:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>Credit Cards</li>
          <li>Debit Cards</li>
          <li>Net Banking</li>
          <li>UPI</li>
          <li>Wallets like Paytm and PhonePe</li>
        </ul>
        <h2 className="text-xl font-semibold mt-6 mb-2">
          5. Delivery Tracking
        </h2>
        <p className="mb-4">
        Once your order has been shipped, you can track its status using the tracking number provided in your confirmation email. You can also track your order by logging into your account.

        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">
          6. Refund and Returns
        </h2>
        <p className="mb-4">
        If you have any issues with your order, such as damaged or incorrect items, please contact our support team within 7 days of
        receiving the product. We will arrange for a return and process a refund through Razorpay.
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">
          7. Contact Us
        </h2>
        <p className="mb-4">
        For any queries related to shipping or payments, please reach out to our support team at <a href="mailto:support@gansgps.in" className="text-blue-500">support@gansgps.com</a>.
        </p>
      </div>
    </div>
  );
};

export default ShippingDelivery;
