
import { useState } from 'react';
import { Header, Shop, Feature, Installation, Blogs, Tracking, Coupons } from './components/Main';
import CardSection from './components/CardSection';
import WhyChooseSection from './components/WhyChooseSection';
import { compare } from './Assets/Main'; // Ensure correct path for image imports

export default function App() {
  const [showCoupons, setShowCoupons] = useState(false);

  const toggleCoupons = () => {
    setShowCoupons(prevState => !prevState);
  };

  return (
    <>
      <div className='overflow-hidden'>
        <Header />

        {/* Coupons Button */}
        {showCoupons?"":<button
          className="border-2 bg-red fixed top-[50%] right-0 z-[100] p-[5px_25px] bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-[#fff] rounded-[5px] cursor-pointer"
          onClick={toggleCoupons}
         style={{zIndex:100}}>
          Coupons
        </button>}

        {/* Conditionally render the Coupons component with slide-in animation */}
        <div
          className={`fixed top-0 right-0 h-full w-full md:w-1/3 bg-white shadow-lg z-[999] transition-transform duration-500 ${
            showCoupons ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          {/* Close Button */}
          <button
            className="absolute top-2 right-2 text-[40px] font-bold text-gray-y600"
            onClick={toggleCoupons}
          >
            &times;
          </button>
          <Coupons />
        </div>

        <Shop />
        <Installation />
        <img src={compare} alt="" />
        <CardSection />
        <Tracking />
        <WhyChooseSection />
        <Feature />
        {/* <Blogs /> */}
      </div>
    </>
  );
}
