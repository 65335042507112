// components/WhyChooseSection.js
import React, { useEffect, useState } from "react";
import { slider5 } from "../Assets/Main";

import {
  FaCheckCircle,
  FaClipboardCheck,
  FaCogs,
  FaHeadset,
} from "react-icons/fa";
// C:\Users\gambh\Downloads\trackingweb (2) (1)\trackingweb\src\app\Assets\images\banner\chooseapp.mp4
// ../Assets/images/banner/chooseapp.mp4
const WhyChooseSection = () => {
  const [mobile,isMobile]=useState(false);
   useEffect(()=>{
     if(window.innerWidth<=680){
      isMobile(true)
     }
   }
  )
  return (
    <div className="relative w-full h-screen mt-[30px] flex flex-col justify-center items-center max-sm:h-fit">
     <video className=" max-sm:w-[100%] max-sm:h-auto"
                        src="https://gansgps.com/admin/storage/app/public/chooseapp.mp4"
                        controls
                        autoPlay
                        style={{
                          position: "relative",
                          width: mobile?"100%":"60%",
                          height: "auto",
                          margin: "auto",
                          background: "transparent",
                          transformStyle: "preserve-3d",
                   
                        }}
                      />
    </div>
  );
};

export default WhyChooseSection;
