// components/CardSection.js
import React from 'react';
import { delivery, car, food, tracking, kids } from '../Assets/Main'; // Ensure correct path for image imports

const CardSection = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center p-4">
      {[
        { title: "ASSET", imageUrl: delivery },
        { title: "VEHICLE", imageUrl: car },
        { title: "FOOD", imageUrl: food },
        { title: "LOGISTICS", imageUrl: tracking },
        { title: "KIDS", imageUrl: kids },
      ].map((item, index) => (
        <div
          key={index}
          className="relative flex flex-col justify-center items-center m-4 bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 transform hover:scale-105" // Scale animation on hover
          style={{ width: '300px', height: '400px' }} // Set fixed dimensions for the card
        >
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{
              backgroundImage: `url(${item.imageUrl})`,
              filter: 'brightness(0.7)', // Adjusted brightness to make the image slightly more visible
            }}
          ></div>
          <div className="absolute inset-0 bg-gradient-to-br  transition duration-300"></div> {/* More transparent gradient overlay */}
          <div className="relative z-10 flex flex-col justify-center items-center h-full p-4 text-white">
            <h1 className="text-[40px] font-bold">{item.title}</h1>
            
          </div>
        </div>
      ))}

    </div>
  );
};

export default CardSection;
