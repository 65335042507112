import { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { track2, features6, features5, features3, features4, features7 } from '../Assets/Main';

function Features() {
  const content = [
    {
      "title": "Real-Time Vehicle Tracking",
      "description": "Experience precise tracking of your vehicle's location using our advanced GPS antenna. The system updates your vehicle's location every 10 seconds when in motion and ensures constant connectivity during idle periods.",
      "isView": true,
      "image": features4 // Add the specific image for this content
    },
    {
      "title": "Comprehensive Daily Metrics",
      "description": "Access a detailed summary of your vehicle’s daily performance, including metrics such as Run Time, Idle Time, Stoppage Time, Distance Traveled, Average Speed, and Maximum Speed. All statistics are available on a daily basis for thorough analysis.",
      "isView": false,
      "image": features3 // Specific image for this content
    },
    {
      "title": "Detailed Historical Playback",
      "description": "Analyze your vehicle’s past driving activities with detailed playback history, including stoppages, speed variations, and runtime analysis. This feature allows you to stay informed about your vehicle’s performance and activities.",
      "isView": true,
      "image": features5 // Specific image for this content
    },
    {
      "title": "Geofencing Alerts",
      "description": "Set up geofences around specific areas to receive alerts when your vehicle enters or exits these zones. This feature helps you keep track of your vehicle's movements within designated areas and enhances security.",
      "isView": false,
      "image": features6 // Specific image for this content
    },
    {
      "title": "Immediate Antitheft Notifications",
      "description": "Receive instant alerts if your vehicle is suspected of being tampered with or moved without authorization. Our antitheft alarm system provides real-time notifications to keep your vehicle secure.",
      "isView": true,
      "image": track2 // Specific image for this content
    },
    {
      "title": "Device Sharing Capabilities",
      "description": "Easily share access to your tracking device with multiple users for a specified duration. You can also create public links to allow others to view your vehicle’s activity without requiring direct access to the device.",
      "isView": false,
      "image": features7 // Specific image for this content
    },
  ];

  const [isMobile, setIsMobile] = useState(false);

  const checkMobileView = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    checkMobileView();
    AOS.init({
      offset: 400,
      delay: 100,
      duration: 1000
    });
  }, []);

  return (
    <div className="mt-[120px] text-center mb-[120px] mx-[120px] max-sm:mx-[20px]">
      <h1 className="text-[1.8rem] font-bold max-sm:text-[1.3rem]">Real-Time GPS Asset Tracking</h1>
      <p className="text-[20px] max-sm:text-[.7rem]">Our GPS tracking system lets you manage your assets remotely, giving you peace of mind.</p>
      {content.map((element, index) => {
        return (
          <div key={index} className={`relative flex flex-wrap ${element.isView ? '' : "flex-row-reverse max-sm:flex-row"} justify-around mt-[50px]`}>
            <div className="w-[50%] max-sm:w-[100%] text-left flex justify-center items-center">
              <div>
                <h1 className="text-[2.5rem] font-semibold leading-[50px] text-[#26848E] max-sm:text-[1.2rem]">{element.title}</h1>
                <p className="mt-[25px] text-[20px] leading-[28px] max-sm:text-[.7rem] max-sm:leading-[19px] max-sm:mt-[5px]">{element.description}</p>
              </div>
            </div>

            {/* Responsive Image Section */}
            <div data-aos={`${element.isView ? 'fade-left' : 'fade-right'}`} className="mt-4 w-[50%] max-sm:w-[100%]" style={{ perspective: "100em" }}>
              <img
                style={{
                  position: "relative",
                  width: "60%",
                  height: isMobile ? "350px" : "690px",
                  margin: "auto",
                  background: "transparent",
                  transformStyle: "preserve-3d",
                  transform: element.isView ? `rotate3d(0, 10, ${isMobile ? 0 : -3}, ${isMobile ? 0 : -20}deg)` : `rotate3d(0, 10, ${isMobile ? 0 : -3}, ${isMobile ? 0 : 15}deg)`
                }}
                className="shadow-[0_0_10px_rgba(0,0,0,0.2)] rounded-[10px] max-sm:w-[100%] max-sm:h-[200px]"
                src={element.image} // Dynamically load the specific image
                alt={element.title}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Features;
