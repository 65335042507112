
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Coupon = () => {
  const [couponsItems, setCartItems] = useState([]);

  useEffect(() => {
    async function getAllCoupon() {
      const response = await axios.get('https://gansgps.com/admin/api/allCoupons');
      // console.log(response);
      setCartItems(response.data.data);
    }
    getAllCoupon();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen space-y-6 p-4 sm:p-6 lg:p-8">
      {couponsItems.map((coupon, index) => (
        <div
          key={index}
          className="relative bg-blue-600 text-white rounded-lg p-4 w-full sm:w-80 md:w-96 h-40 flex justify-between items-center shadow-lg transition-transform transform hover:scale-105"
        >
          <div className="flex flex-col items-center justify-center">
            <span className="text-3xl sm:text-4xl font-bold ml-3">{coupon.discount_value}%</span>
          </div>
          <div className="flex flex-col items-center justify-center">
            <span className="text-xl sm:text-2xl font-bold">COUPON</span>
            <span className="text-sm sm:text-lg font-mono bg-gray-100 text-blue-600 p-1 rounded-lg tracking-wider">
              {coupon.coupon_code}
            </span>
            <span className="text-xs sm:text-sm mt-2">UNTIL {coupon.expiry || "coming soon"}</span>
            <button
              className="mt-2 text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded-lg shadow-lg transition-transform transform hover:scale-105"
              onClick={() => {
                navigator.clipboard.writeText(coupon.coupon_code);
                alert("Coupon has been successfully copied");
              }}
            >
              COPY
            </button>
          </div>
          <div className="absolute top-0 bottom-0 left-1/3 border-l-2 border-dotted border-white"></div>
          <div className="absolute inset-y-0 left-0 flex items-center">
            <div className="bg-white w-4 h-8 sm:w-5 sm:h-10 rounded-r-full"></div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center">
            <div className="bg-white w-4 h-8 sm:w-5 sm:h-10 rounded-l-full"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Coupon;
