import React from "react";

const ReturnPolicy = () => {
  return (
    <div className="bg-gray-100 min-h-screen p-8 relative top-[70px]">
      <div className="max-w-3xl mx-auto bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-3xl text-center font-bold mb-4">Return Policy</h1>

        <p className="mb-4">
          The items sold on GANS Telematics are eligible for free
          refund/replacement within 10 days of delivery, in the unlikely event
          of a damaged, defective, or different item delivered to you.
        </p>

        <p className="mb-4">
          Please keep the item in its original condition, with the brand outer
          box, user manual, and original accessories in manufacturer packaging
          for a successful return. We may contact you to ascertain the damage or
          defect in the product prior to issuing a replacement.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">1. Terms for Return</h2>
        <ul className="list-disc ml-6 mb-4">
          <li>
            The return or replacement is only valid for items purchased directly
            from GANS Telematics.
          </li>
          <li>
            he product must be in its original packaging and in the same
            condition as delivered.
          </li>
          <li>
            Items damaged due to misuse or accidental damage are not covered
            under this return policy.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          2. Process for Return
        </h2>
        <p className="mb-4">
          To initiate a return, please contact our customer service with your
          order ID and the reason for return. Our team will guide you through
          the process and arrange for the return of the item.
        </p>
        <p className="mb-4">
          Once the returned product is received and inspected, a refund or
          replacement will be processed. Please allow up to 7 working days for
          refunds to reflect in your account.
        </p>
      </div>
    </div>
  );
};

export default ReturnPolicy;
