import logo from './logo.svg';
import './App.css';
import { BrowserRouter ,Routes,Route } from 'react-router-dom';
import {Navbar,Footer,Singlepageproduct, ContactForm} from './components/Main'
import Home from './Home'
import ListProduct from './Products';
// import ContactForm from './ContactUs';
import Dashboard from './Dashboard'
import {CheckProviders} from './context/checkAuth'
import ProvacyPolicy from './components/privacy_policy';
import ShippingDelivery from './components/shipping';
import TermsAndConditions from './components/term_and_conditions';
import ReturnPolicy from './components/refund_policy';
function App() {
  return (
    <div>
         <BrowserRouter>
         <CheckProviders>
      <Navbar/>
   
   
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/products' element={<ListProduct />}/>
        <Route path='/contactus' element={<ContactForm />}/>
        <Route path='/dashboard' element={<Dashboard />}/>
        <Route path='/Product/:productId' element={<Singlepageproduct />}/>
        <Route path='/privacy_policy' element={<ProvacyPolicy />} />
        <Route path='/shipping' element={<ShippingDelivery />} />
        <Route path='/term_and_conditions' element={<TermsAndConditions />} />
        <Route path='/refund_policy' element={<ReturnPolicy />} />
        
        </Routes> 
        <Footer/>
        </CheckProviders>
      </BrowserRouter>
    </div>
  );
}

export default App;
