
import { createContext, useState } from "react";

export const CheckAuthContext=createContext();

 export const CheckProviders=(props)=>{
    const [checkAuth,setCheckAuth]=useState(false);
  return <CheckAuthContext.Provider value={{checkAuth, setCheckAuth}}>
    {props.children}
  </CheckAuthContext.Provider>
}