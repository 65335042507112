import React from "react";

const ProvacyPolicy = () => {
  return (
    <div className="bg-gray-100 min-h-screen p-8 relative top-[70px]">
      <div className="max-w-3xl mx-auto bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-3xl text-center font-bold mb-4">Privacy Policy</h1>

        <p className="mb-4">
          We, GANS Telematics, are registered at A-105 Street No. 3, Ganga
          Vihar, Delhi 110094, Corporate office - Second Floor, A67 Sector 2
          Noida 201301 hereinafter referred to as GANS. At GANS, we value your
          trust & respect your privacy. This Privacy Policy provides you with
          details about the manner in which your data is collected, stored &
          used by us. You are advised to read this Privacy Policy carefully. By
          downloading and using the GANS application/ website/WAP site you
          expressly give us consent to use & discloseyour personal information
          in accordance with this Privacy Policy. If you do not agree to the
          terms of the policy, please do not use or access GANS. This SERVICE is
          provided by GANS at no cost and is intended for use as is. This page
          is used to inform visitors regarding our policies with the collection,
          use, and disclosure of Personal Information if anyone decided to use
          our Service. If you choose to use our Service, then you agree to the
          collection and use of information in relation to this policy. The
          Personal Information that we collect is used for providing and
          improving the Service. We will not use or share your information with
          anyone except as described in this Privacy Policy. The terms used in
          this Privacy Policy have the same meanings as in our Terms and
          Conditions, which is accessible at GANS unless otherwise defined in
          this Privacy Policy. Note: Our privacy policy may change at any time
          without prior notification. To make sure that you are aware of any
          changes, kindly review the policy periodically. This Privacy Policy
          shall apply uniformly to GANS applications, desktop website & mobile
          WAP site.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          1. INFORMATION COLLECTION AND USE
        </h2>
        <p className="mb-4">
          For a better experience, while using our Service, we may require you
          to provide us with certain personally identifiable information,
          including but not limited to INTERNET, ACCESS_NETWORK_STATE,
          WRITE_EXTERNAL_STORAGE, WAKE_LOCK, READ_PHONE_STATE, READ_GSERVICES,
          ACCESS_FINE_LOCATION, ACCESS_COARSE_LOCATION, READ_SMS, RECEIVE_SMS,
          SEND_SMS, WRITE_SMS, CAMERA. The information that we request will be
          retained by us and used as described in this privacy policy. Apart
          from this, GANS uses the Google Analytics to measure the usage of GANS
          application by user. Google Analytics collects information about
          user’s browser and computer settings, like screen resolution,
          operating system, etc. The following link will redirect the user to
          better understand how google analytics collects and process data to
          application owner. For a better experience, while using our Service,
          we may require you to provide us with certain personally identifiable
          information, including but not limited to INTERNET,
          ACCESS_NETWORK_STATE, CALL_PHONE, WRITE_EXTERNAL_STORAGE, WAKE_LOCK,
          READ_GSERVICES, ACCESS_FINE_LOCATION, ACCESS_COARSE_LOCATION,
          READ_SMS, RECEIVE_SMS, SEND_SMS, WRITE_SMS, CAMERA. The information
          that we request will be retained by us and used as described in this
          privacy policy.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          2. Link to privacy policy of third party service providers used by the
          app
        </h2>
        <p className="mb-4">
          The product and applications are not intended for use by individuals
          under the age of 18. By using the product, you confirm that you meet
          the eligibility requirements.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          3. LINKS TO OTHER SITES
        </h2>
        <p className="mb-4">
          Our site links to other websites that may collect personally
          identifiable information about you. GANS is not responsible for the
          privacy practices or the content of those linked websites.
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">
          4. USE OF PERSONAL INFORMATION
        </h2>
        <p className="mb-4">
          We use personal information to provide you with services & products
          you explicitly requested for, to resolve disputes, troubleshoot
          concerns, help promote safe services, collect money, measure consumer
          interest in our services, inform you about offers, products, services,
          updates, customize your experience, detect & protect us against error,
          fraud and other criminal activity, enforce our terms and conditions,
          etc. We use incoming calls data to make userâ€™s experience better
          whenever they receive call from driver for assistance.
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">5. LOG DATA</h2>
        <p className="mb-4">
          We want to inform you that whenever you use our Service, in a case of
          an error in the app we collect data and information (through third
          party products) on your phone called Log Data. This Log Data may
          include information such as your device Internet Protocol address,
          device name, operating system version, the configuration of the app
          when utilizing our Service, the time and date of your use of the
          Service, and other statistics.
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">6. COOKIES</h2>
        <p className="mb-4">
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your devices internal
          memory. This Service does not use these explicitly. However, the app
          may use third party code and libraries that use to collect information
          and improve their services. You have the option to either accept or
          refuse these cookies and know when a cookie is being sent to your
          device. If you choose to refuse our cookies, you may not be able to
          use some portions of this Service.
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">
          7. SERVICE PROVIDERS
        </h2>
        <p className="mb-4">
          We may employ third-party companies and individuals due to the
          following reasons: To facilitate our Service To provide the Service on
          our behalf To perform Service-related services To assist us in
          analyzing how our Service is used We want to inform users of this
          Service that these third parties have access to your Personal
          Information. The reason is to perform the tasks assigned to them on
          our behalf. However, they are obligated not to disclose or use the
          information for any other purpose.
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">8. SECURITY</h2>
        <p className="mb-4">
          GANS has stringent security measures in place to protect the loss,
          misuse, and alteration of the information under our control. Whenever
          you change or access your account information, we offer the use of a
          secure server. Once your information is in our possession we adhere to
          strict security guidelines, protecting it against unauthorized access.
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">
          9. LINKS TO OTHER SITES
        </h2>
        <p className="mb-4">
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">10. WHATSAPP POLICY</h2>
        <p className="mb-4">
          By signing in to the GANS, you are providing your consent to receive
          the important notifications through WhatsApp.
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">
          11. CHILDREN`&apos`S PRIVACY
        </h2>
        <p className="mb-4">
          These Services do not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from children
          under 13. In the case we discover that a child under 13 has provided
          us with personal information, we immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with personal information, please contact us so
          that we will be able to do necessary actions.
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">CONTACT US</h2>
        <p className="text-sm">
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at official@GANS.in and tech@GANS.in
        </p>
      </div>
    </div>
  );
};

export default ProvacyPolicy;
