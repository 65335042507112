
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { FaBars, FaEllipsisV, FaPlus } from 'react-icons/fa';
import axios from 'axios';

// Dummy content components
const Ordercard = ({ product, final_amount }) => (
  <div className="w-full mx-auto p-4 border shadow-sm rounded-md mt-4">
    <div className="flex items-center gap-4 flex-wrap mb-4">
      <img
        src={`https://gansgps.com/admin/storage/app/public/${product.feature_image}`}
        alt={product.name}
        className="w-20 h-20 object-cover"
      />
      <div className="flex-grow">
        <h3 className="text-lg font-semibold">{product.name}</h3>
        <p className="text-sm text-gray-500">{product.description}</p>
        <p className="text-xl font-bold text-green-500 mt-2">{final_amount}</p>
      </div>
      <div className="text-green-600 text-sm font-semibold">
        <span className="block">🟢</span>
        <span>Delivery expected by Oct 14</span>
      </div>
    </div>
    <div className="mt-4 text-gray-500 text-sm flex justify-between">
      <h3>Your Order has been placed.</h3>
      <h3>Tracking id:11234567890</h3>
    </div>
  </div>
);

const Orders = () => {
  const [ordersItems, setOrders] = useState([]);

  useEffect(() => {
    const customer_id = localStorage.getItem('customer_id');
    const token = localStorage.getItem('token');
    const header = {
      Authorization: `Bearer ${token}`,
    };

    async function getOrders() {
      try {
        const response = await axios.get(
          `https://gansgps.com/admin/api/orders/user_id_${customer_id}`,
          { headers: header }
        );
        setOrders(response.data.orders);
      } catch (err) {
        console.error('Error fetching orders:', err);
      }
    }
    getOrders();
  }, []);

  return (
    <div>
      {ordersItems.map((order) => (
        <Ordercard key={order.id} {...order} />
      ))}
    </div>
  );
};

const Profile = () => (
  <div className="w-full mx-auto bg-white rounded-lg p-8">
    <div className="mb-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Personal Information</h2>
        <a href="#" className="text-teal-600 hover:text-teal-700">
          Edit
        </a>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <input
          type="text"
          placeholder="First Name"
          className="w-full p-3 border rounded-md focus:outline-none focus:border-teal-500"
        />
        <input
          type="text"
          placeholder="Last Name"
          className="w-full p-3 border rounded-md focus:outline-none focus:border-teal-500"
        />
      </div>
    </div>

    <div className="mb-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Email Address</h2>
        <a href="#" className="text-teal-600 hover:text-teal-700">
          Edit
        </a>
      </div>
      <input
        type="email"
        placeholder="Email Address"
        className="w-full p-3 border rounded-md focus:outline-none focus:border-teal-500"
      />
    </div>

    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Mobile Number</h2>
        <a href="#" className="text-teal-600 hover:text-teal-700">
          Edit
        </a>
      </div>
      <input
        type="text"
        placeholder="Mobile Number"
        className="w-full p-3 border rounded-md focus:outline-none focus:border-teal-500"
      />
    </div>
  </div>
);

const Address = () => {
  const [addresses, setAddresses] = useState([
    {
      id: 1,
      name: 'Vansh Deep Arya',
      phone: '7906837086',
      address: 'C46 Durga nagar, Bareilly, Uttar Pradesh 243005',
      pincode: '243005',
      label: 'Home',
    },
    {
      id: 2,
      name: 'Deeksha Arya',
      phone: '7906837086',
      address: 'Gali number 16, Vipin Garden, Nawada, South West Delhi, Delhi',
      pincode: '110059',
    },
  ]);

  return (
    <div className="w-full">
      <div className="w-full mx-auto bg-white rounded-lg p-4">
        <h1 className="text-2xl font-bold mb-4">Manage Addresses</h1>
        <button className="flex items-center text-blue-600 hover:text-blue-700 font-semibold mb-6">
          <FaPlus className="mr-2" /> ADD A NEW ADDRESS
        </button>
        {addresses.map((address) => (
          <div
            key={address.id}
            className="flex justify-between items-center p-4 border rounded-lg mb-4 bg-gray-100"
          >
            <div>
              {address.label && (
                <div className="bg-gray-200 text-xs inline-block px-2 py-1 rounded mb-1">
                  {address.label.toUpperCase()}
                </div>
              )}
              <p className="font-semibold">
                {address.name}{' '}
                <span className="font-normal ml-2">{address.phone}</span>
              </p>
              <p className="text-gray-600">
                {address.address} -{' '}
                <span className="font-semibold">{address.pincode}</span>
              </p>
            </div>
            <div className="text-gray-500 cursor-pointer">
              <FaEllipsisV />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Reviews = () => <div>Ratings & Reviews</div>;

const Sidebar = () => {
  const [activeContent, setActiveContent] = useState('Orders');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  function logoutUser() {
    localStorage.removeItem('customer_id');
    localStorage.removeItem('token');
    navigate('/');
  }

  const renderContent = () => {
    switch (activeContent) {
      case 'Orders':
        return <Orders />;
      case 'Profile':
        return <Profile />;
      case 'Address':
        return <Address />;
      case 'Reviews':
        return <Reviews />;
      default:
        return <Orders />;
    }
  };

  return (
    <div className="flex mt-[100px] w-full m-auto mb-[50px] shadow-lg border-2 h-[70vh] rounded-lg">
      {/* Mobile Sidebar Toggle */}
      <button
        className="md:hidden text-2xl p-2"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <FaBars />
      </button>

      {/* Sidebar */}
      <div
        className={`${
          isSidebarOpen ? 'block' : 'hidden'
        } md:block md:w-64 bg-gray-100 h-full rounded-tl-lg rounded-bl-lg`}
      >
        <div className="p-6">
          <div className="flex items-center mb-4">
            <div className="bg-yellow-500 h-12 w-12 rounded-full"></div>
            <h2 className="text-xl font-semibold ml-4">Hello,</h2>
          </div>
          <ul className="space-y-4">
            <li
              className={`flex items-center p-2 rounded-lg cursor-pointer ${
                activeContent === 'Orders' ? 'bg-teal-100 text-teal-700' : ''
              }`}
              onClick={() => {
                setActiveContent('Orders');
                setIsSidebarOpen(false);
              }}
            >
              <i className="fas fa-shopping-cart mr-2"></i> My Orders
            </li>
            <li
              className={`flex items-center p-2 rounded-lg cursor-pointer ${
                activeContent === 'Profile' ? 'bg-teal-100 text-teal-700' : ''
              }`}
              onClick={() => {
                setActiveContent('Profile');
                setIsSidebarOpen(false);
              }}
            >
              <i className="fas fa-user-cog mr-2"></i> Profile Information
            </li>
            <li
              className={`flex items-center p-2 rounded-lg cursor-pointer ${
                activeContent === 'Address' ? 'bg-teal-100 text-teal-700' : ''
              }`}
              onClick={() => {
                setActiveContent('Address');
                setIsSidebarOpen(false);
              }}
            >
              <i className="fas fa-address-book mr-2"></i> Manage Address
            </li>
            <li
              className={`flex items-center p-2 rounded-lg cursor-pointer ${
                activeContent === 'Reviews' ? 'bg-teal-100 text-teal-700' : ''
              }`}
              onClick={() => {
                setActiveContent('Reviews');
                setIsSidebarOpen(false);
              }}
            >
              <i className="fas fa-pen mr-2"></i> Ratings & Reviews
            </li>
            <li className="flex items-center p-2 rounded-lg cursor-pointer text-red-500">
              <i className="fas fa-power-off mr-2"></i>
              <button onClick={logoutUser}>Log Out</button>
            </li>
          </ul>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 bg-white p-4 h-full overflow-y-auto rounded-tr-lg rounded-br-lg">
        {renderContent()}
      </div>
    </div>
  );
};

export default Sidebar;
