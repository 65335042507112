

import { useEffect, useState } from 'react'
import axios from 'axios'
import { Product ,Coupons, Shop} from './components/Main'

export default function ListProduct() {
    const [data, setData] = useState([])
    const [showCoupons, setShowCoupons] = useState(false);

    const toggleCoupons = () => {
      setShowCoupons(prevState => !prevState);
    };
    useEffect(() => {
        async function getAllProduct() {
            try {
                const response = await axios.get('https://gansgps.com/admin/api/getallproduct');
                // console.log(response.data);
                setData(response.data.products);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        }

        getAllProduct();
    }, []);

    return (
        <>
        {showCoupons?"":<button
          className="border-2 bg-red fixed top-[50%] right-0 z-[100] p-[5px_25px] bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-[#fff] rounded-[5px] cursor-pointer"
          onClick={toggleCoupons}
         style={{zIndex:100}}>
          Coupons
        </button>}

        {/* Conditionally render the Coupons component with slide-in animation */}
        <div
          className={`fixed top-0 right-0 h-full w-full md:w-1/3 bg-white shadow-lg z-[999] transition-transform duration-500 ${
            showCoupons ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          {/* Close Button */}
          <button
            className="absolute top-2 right-2 text-xl font-bold text-gray-600"
            onClick={toggleCoupons}
          >
            &times;
          </button>
          <Coupons />
        </div>
            {/* <div className="grid gap-10 mt-24 mx-[120px] max-sm:mr-0 
            max-sm:ml-5
        sm:grid-cols-1
        md:grid-cols-2
        lg:grid-cols-3
        xl:grid-cols-3">
                {data.map((element, index) => (
                    <Product key={index} product={element} />
                ))}
            </div> */}
            <Shop />
        </>
    );
}
