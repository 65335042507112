import FAQItem from "./FAQItem";
import { useContext, useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import axios from "axios";
import Checkout from "./Checkout";
import { useParams } from "react-router-dom";
import {CheckAuthContext} from '../context/checkAuth'
import Authentication from './Authentication'

const initialReviews = [
  {
    rating: 5,
    title: "Excellent",
    content:
      "This is a very good quality product, the build quality is great and anyone can install it on their own. The tracking is very accurate and quick.",
    author: "Shubham Sami",
    verified: true,
  },
  {
    rating: 5,
    title: "Perfect tracker for your vehicle.",
    content:
      "Product is awesome. Installation is easy if you have basic circuit knowledge.",
    author: "Amazon Customer",
    verified: true,
  },
];

const averageRating = 4.7;
const totalRatings = 159;
const totalReviews = "500+";

export default function Singlepageproduct() {
  const [showMore, setShowMore] = useState(false);
  const [pincode, setPincode] = useState("");
  const [productData, setProductData] = useState({});
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [featuresOptions, setFeaturesOptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [checkoutPopup, setCheckoutPopup] = useState(false);
  const [multipleImage, setMultipleImage] = useState([]);
  const [OldPoductPrice, setOldPoductPrice] = useState(0);
  const [isSub,setSub]=useState(0)
  const [newPoductPrice, setnewPoductPrice] = useState(0);
  const [selectedImage, setSelectedImage] = useState("");
  const [authScreen, setAuthScreen] = useState(false);
  const Authenticated=useContext(CheckAuthContext);

  // const { product_id } = props;
  const { productId } = useParams();
  useEffect(() => {
    // console.log(productId); 

    const fetchProductData = async () => {
      try {
        const response = await axios.get(
          `https://gansgps.com/admin/api/product/product_id_${productId}`
        );
        setProductData(response.data.product);
        setSubscriptionOptions(response.data.product.subscription || []);

        setFeaturesOptions(response.data.product.features);
        setMultipleImage(
          JSON.parse(response.data.product.multiple_images) || []
        );
        setnewPoductPrice(response.data.product.subscription.old_price);
        setSelectedImage(response.data.product.feature_image);
        setOldPoductPrice(response.data.product.subscription[0].old_price);
        setSub(response.data.product.subscription[0].id);
        // console.log(response,isSub);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductData();
  }, [productId]);
// console.log(selectedSubscription)
  const features = [featuresOptions];
// console.log(productId);
  const handlePincodeChange = (e) => {
    setPincode(e.target.value);
  };
  
  
  async function HandleBuyNow(e) {
    e.stopPropagation(); // Stop the card click event

    const token = localStorage.getItem('token');
    const customer_id = localStorage.getItem('customer_id');
    


    if (!token || !customer_id) {
      // console.log('Authentication required');
       setAuthScreen(true);
      return;
    } else {
      Authenticated.setCheckAuth(true);
      setCheckoutPopup(true); // Open modal for checkout
    }
  }
  function  setAuthPart(){
    setAuthScreen(false);
  }
  const checkPincode = () => {
    if (!pincode) {
      alert("Please enter your PIN Code");
    } else {
      alert("Delivery Available");
    }
  };
  function setSubscription(index) {
    setSub(subscriptionOptions[index].id)
    setnewPoductPrice(subscriptionOptions[index].new_price);
    setOldPoductPrice(subscriptionOptions[index].old_price);
    // console.log(isSub);
  }
  const closeModal = () => setCheckoutPopup(false);

  const combinedFaqs = (() => {
    try {
      const qaArray = JSON.parse(productData.qa); // Parse the Q&A from productData
      return Array.isArray(qaArray)
        ? qaArray.map((item) => ({
            question: item.Ques.replace("Q: ", ""), // Remove the "Q: " prefix
            answer: item.Answer.replace("A:", ""), // Remove the "A: " prefix
            seller: "", // Leave it as an empty string or set a value
          }))
        : [];
    } catch (error) {
      console.error("Error parsing Q&A:", error);
      return []; // Return an empty array in case of error
    }
  })();

  return (
    <div className="container-fluid p-6 max-sm:p-2 mt-[80px]  mx-[200px] max-sm:mx-0 border-2">
      {checkoutPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
          <div className="bg-white rounded-lg shadow-lg w-[90%] max-w-[500px] p-6 relative">
            <Checkout
              closemodel={closeModal}
              product_id={productId}
              amount={OldPoductPrice}
              product_name={productData.name}
              subId={isSub}
            />
          </div>
        </div>
      )}
      {authScreen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-[90%] max-w-[500px] p-6 relative">
           
            <Authentication sendMessage={setAuthPart}/> 
          </div>
        </div>
      )}
      {/* Main wrapper for the product page */}
      <div className="flex justify-evenly md:flex-col lg:flex-row max-sm:flex-col">
        {/* Left: Image Section (Sticky container) */}
        <div className="sticky max-sm:static top-[100px] flex max-sm:block justify-center self-start w-full  mr-[20px] max-sm:mr-0 900:top-0 900:relative">
          <div className="mr-[10px] max-sm:flex gap-[10px] justify-center">
            {multipleImage.map((element, index) => (
              <div key={index} className="w-[70px] h-[70px] mb-[10px]">
                <img
                  src={`https://gansgps.com/admin/storage/app/public/${element}`}
                  onClick={() => {
                    setSelectedImage(element);
                  }}
                  alt="loading .."
                  className="rounded-lg bg-black w-[100%] h-[100%]"
                />
              </div>
            ))}
          </div>
          <div className="w-[100%] max-sm:w-[100%]">
            <img
              src={`https://gansgps.com/admin/storage/app/public/${selectedImage}`}
              alt="Product Image"
              className="rounded-lg bg-black w-[100%] h-[500px] max-sm:h-[400px] "
            />
            <div className="mt-[10px]">
              <button
                className="bg-yellow-500 max-sm:fixed max-sm:bottom-[8px]  w-[100%] text-white py-2 mr-[5px] max-sm:w-[95%]  max-sm:py-3 max-sm:bg-green-500 max-sm:m-auto  rounded-[5px] mb-4"
                onClick={(e) => HandleBuyNow(e)} 
              >
                Buy Now
              </button>
              {/* <button className="bg-orange-500 w-[49%] text-white py-2 rounded-[5px]">Add to Cart</button> */}
            </div>
          </div>
        </div>

        {/* Right: Product Details (Scrollable) */}
        <div className="overflow-y-auto h-fit w-full 900:w-[60%]">
          <h1 className="text-3xl font-bold mb-2 max-sm:text-[1.2rem]">
            {productData.name}
          </h1>
          {/* <p className="text-gray-600 mb-[15px]  max-sm:text-[.7rem]">
            {productData.description}
          </p> */}

          <div className="flex items-center mb-[15px]">
            <span className="flex justify-center items-center p-[5px] bg-[#26848E] rounded-[5px] text-[.75rem] text-[#fff] w-fit mr-[10px]">
              <FaStar className="mr-[3px]" /> 4.8
            </span>
            <span className="text-[#746e6e] text-[1.6rem] max-sm:text-[.8rem]">
              {totalReviews} Reviews
            </span>
          </div>

          <span className="text-xl font-semibold text-green-600 mb-2 block">
            ₹{OldPoductPrice}{" "}
            <span className="line-through text-[16px] text-gray-500">
              ₹{productData.new_price}
            </span>{" "}
            76% Off
          </span>
          <span className="text-[1.6rem] text-[#746e6e] max-sm:text-[.8rem]">
            Inclusive of all taxes
          </span>

          <div className="mt-4 mb-[30px]">
            <h3 className="text-[1.1rem] font-bold mb-[10px]">
              Choose Variant
            </h3>
            <div className="flex items-center flex-wrap gap-[5px]">
              {subscriptionOptions.map((option, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setSelectedSubscription(index);
                    setSubscription(index);
                  }}
                  className={`w-[calc(50%-10px)] sm:w-[200px] max-sm:m-auto h-[auto] p-[5px] border-2 rounded-[10px] cursor-pointer ${
                    selectedSubscription === index
                      ? "border-[#26848E] bg-gray-100"
                      : "border-gray-200"
                  }`}
                >
                  <h5 className="text-[16px] font-bold mb-[5px]">
                  ₹{option.old_price}
                  </h5>
                  <p className="text-[12px] mb-[5px]">{option.plan_name}</p>
                  <h5 className="text-[16px] font-bold">
                  ₹{option.new_price} per year
                  </h5>
                </div>
              ))}
            </div>
          </div>

          <div className="my-6 font-sans mb-[30px] ">
            {/* <div className="flex items-center space-x-2 mb-4 max-sm:flex-col">
              <span className="font-semibold max-sm:mb-[5px]">Delivery :</span>
              <input
                type="text"
                placeholder="Enter Delivery Pincode"
                onChange={handlePincodeChange}
                className="w-[400px]  max-sm:w-[83%] py-2 border border-gray-300 rounded-[5px] flex-1 pl-[5px] max-sm:mb-[5px]"
                value={pincode}
              />
              <button
                className="px-[30px] py-2 bg-[#26848E] text-white rounded-[5px] hover:bg-teal-600"
                onClick={checkPincode}
              >
                Check
              </button>
            </div> */}

            <h2 className="text-[1.3rem] font-semibold mb-3">
              Product Features
            </h2>
            <ul className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              {Array.isArray(productData.features) &&
              productData.features.length > 0 ? (
                productData.features.map((feature, index) => (
                  <li
                    key={index}
                    className="border p-4 rounded-lg flex items-start space-x-2"
                  >
                    <img
                      src={`https://gansgps.com/admin/storage/app/public/${feature.image}`}
                      alt={feature.heading}
                      className="w-16 h-16"
                    />
                    <div>
                      <h4 className="font-bold">{feature.heading}</h4>
                      <p className="text-gray-600">{feature.description}</p>
                    </div>
                  </li>
                ))
              ) : (
                <li className="text-gray-600">No features available.</li>
              )}
            </ul>

            <div className="flex justify-between mb-6 max-sm:flex-col">
              <div className="w-3/4 pr-2 max-sm:w-full max-sm:pr-0">
                <h3 className="font-semibold mb-3 text-[1.3rem]">Highlights</h3>
                <ul className="text-[1rem] space-y-5">
                  {(() => {
                    try {
                      const highlightsArray = JSON.parse(
                        productData.highlights
                      );
                      return Array.isArray(highlightsArray) ? (
                        highlightsArray.map((highlight, index) => (
                          <li key={index}>{highlight}</li>
                        ))
                      ) : (
                        <li>No highlights available.</li>
                      );
                    } catch (error) {
                      console.error("Error parsing highlights:", error);
                      return <li>No highlights available.</li>;
                    }
                  })()}
                </ul>
              </div>
              <div className="w-1/3 max-sm:w-full">
                <h3 className="font-semibold mb-3 text-[1.3rem]">Services</h3>
                <ul
                  className="text-[1rem] space-y-5"
                  style={{ listStyleType: "disclosure-closed" }}
                >
                  <li> 1 Year of hardware warranty. T&C </li>
                  <li> 20 days easy return policy help_outline </li>
                  <li>Quick delivery avaliable at major cities </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex justify-between mb-6 border-2 p-[10px] rounded-[5px]">
            <div className="w-full">
              <h3 className="font-semibold mb-3 text-[1.3rem]">
                Specifications
              </h3>
              <ul className="border border-gray-300 divide-y divide-gray-200 text-[1rem]">
  {(() => {
    try {
      // Parse the specifications from JSON string to an object
      const specificationsObject = JSON.parse(productData.specifications);

      // Check if it's an object and iterate through it
      if (
        typeof specificationsObject === "object" &&
        specificationsObject !== null
      ) {
        return Object.entries(specificationsObject).map(([key, value], index) => (
          <li key={index} className="flex justify-between items-center p-3">
            <strong className="w-1/3 text-gray-600">{key}:</strong>
            <span className="w-2/3 text-gray-800">{value}</span>
          </li>
        ));
      } else {
        return <li className="p-3">No specifications available.</li>;
      }
    } catch (error) {
      console.error("Error parsing specifications:", error);
      return <li className="p-3">No specifications available.</li>;
    }
  })()}
</ul>

            </div>
          </div>
          <h2 className="text-[1.3rem] font-semibold mb-3">
            Frequently Asked Questions
          </h2>
          {combinedFaqs.length > 0 ? (
            combinedFaqs.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                seller={faq.seller} // Pass seller, even if empty
              />
            ))
          ) : (
            <li className="text-gray-600">No FAQs available.</li>
          )}

          <div className="my-4">
            <h2 className="text-[1.3rem] font-semibold mb-3">
              Customer Reviews
            </h2>
            {initialReviews.map((review, index) => (
              <div key={index} className="border-b border-gray-300 py-4 mb-4">
                <div className="flex items-center mb-1">
                  <span className="flex text-yellow-500">
                    {[...Array(review.rating)].map((_, i) => (
                      <FaStar key={i} />
                    ))}
                  </span>
                  <span className="text-gray-600 ml-2">{review.title}</span>
                </div>
                <p className="text-gray-700">{review.content}</p>
                <p className="text-sm text-gray-500">
                  - {review.author} {review.verified && <span>(Verified)</span>}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
