// components/TrackingSection.js

import {  playStore, AppStore,Tracking } from "../Assets/Main"; // Assuming Logo is the default export


const TrackingSection = () => {
  return (
    <section className="bg-teal-500 text-white flex flex-col items-center justify-center h-screen h-[400px] mt-[70px]" style={{height:"400px"}}>
      <div className="flex flex-col md:flex-row items-center justify-between w-full max-w-5xl px-4 md:px-0 max-sm:flex-col-reverse	">
        <div className="md:w-1/2 flex flex-col items-center md:items-start mb-8 md:mb-0 text-center md:text-left">
          <h1 className="text-[2.5rem] tracking-[1.5px]  max-md:text-5xl font-bold mb-4 max-sm:text-[1.2rem] max-sm:mb-[15px]">
            Tracking your Vehicle is now at your fingertips
          </h1>
          <p className="text-lg mb-6 max-sm:text-[.7rem] max-sm:mb-[20px] max-sm:leading-[18px]">
            A user friendly interface that makes it a better choice for business as well as personal use
          </p>
          <div className="flex gap-4 max-sm:flex-wrap justify-center">
            <a href="https://play.google.com/store/apps/details?id=uffizio.startupvts&hl=en">
            <img
              src={playStore}
              alt="Get it on Google Play"
              width={150}
              height={50}
              className="cursor-pointer border rounded-md"
            />
            </a>
            <a href="https://apps.apple.com/in/app/startup-vts/id1327365993">
            <img
              src={AppStore}
              alt="Download on the App Store"
              width={165}
              height={50}
              className="cursor-pointer"
            />
            </a>
          </div>
        </div>
        <div className="md:w-1/2 flex justify-center">
          <img
            src={Tracking}
            alt="Tracking your vehicle"
            width={400}
            height={800}
            className="rounded-lg max-sm:w-[155px] max-sm:h-[144px] "
          />
        </div>
      </div>
    </section>
  );
};

export default TrackingSection;
